<template>
  <div>
    <hr />
    <div class="row" v-if="$parent.det_enturnes.length > 0">
      <table class="table table-sm table-hover">
        <thead class="thead-dark">
          <tr class="text-center">
            <th scope="col">Fecha</th>
            <th scope="col">Ventana de enturne</th>
            <th scope="col">Turno</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in $parent.det_enturnes" :key="item.id">
            <td>{{ item.fecha_llamado }}</td>
            <td>{{ item.ventana.nombre }}</td>
            <td class="text-center">{{ item.turno_activo }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row" v-else>
      <div class="alert alert-warning col-md-12">
        <h5><i class="icon fas fa-exclamation-triangle"></i> Atención!</h5>
        No se encontraron enturnes para este Viaje!
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "ViajesInfoEnturnes",

  data() {
    return {
      det_enturnes: {},
    };
  },
  methods: {},

  mounted() {},
};
</script>
